import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../Header/header";
import Footer from "../../Footer/Footer";
import HeaderMenu from "../../Header/header-menu";
import Isi from "../../ISI/Isi";
import './Homepage.css'
import IsiFrame from '../../ISI/Isi-frame';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MainLayout from "../../Layouts/Main_Layout";
import { Helmet } from "react-helmet";


export default function Home() {
  const myRef = useRef(null)
  const [show, setShow] = useState(true)
  const executeScroll = () => {
    myRef.current.scrollIntoView();
    setShow(true)
  }
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (location.hash === '#full-ISI') {
        myRef.current.scrollIntoView();
      }
    }, 1000);

    const calculateOffset = () => {
      const componentElement = myRef.current;

      if (myRef) {
        const offsetTop = componentElement.offsetTop;
        function handleScroll() {
          if (window.innerHeight + window.scrollY > offsetTop) {
            setShow(false);
          }
          else (
            setShow(true)
          )
        }

        window.addEventListener("scroll", handleScroll);


        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    };
    calculateOffset();
    window.addEventListener('resize', calculateOffset);
    return () => {
      window.removeEventListener('resize', calculateOffset);
    };
  }, [location]);

  return (
    <MainLayout>
      <Helmet>
        <title>INBRIJA® (levodopa inhalation powder) for Patients</title>
        <meta property="og:description" content="Learn about INBRIJA, Prescription Support Services, and downloadable resources. Watch a video about a person who uses INBRIJA. See Indication, Important Safety Information, and Patient Information Leaflet." />
        <meta property="og:url" content="https://inbrija.com" />
        <meta name="description" content="Learn about INBRIJA, Prescription Support Services, and downloadable resources. Watch a video about a person who uses INBRIJA. See Indication, Important Safety Information, and Patient Information Leaflet." />
        <meta property='og:site_name' content='INBRIJA.COM' />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://inbrija.com/assets/images/Inbrija-OG-Image.jpg" />
        <meta property='og:image:alt' content='INBRIJA logo' />
        <meta property='og:title' content={`INBRIJA® (levodopa inhalation powder) for Patients`} />
      </Helmet>
      <div className="homepage">
        <Header />
        <HeaderMenu>
          <div className="container-1500">
            <div className='hero-section'>
              <div className='hero-section-content'>
                <h1>FIGHT RETURNING PARKINSON'S SYMPTOMS <span>NOW</span></h1>
                <h4><span>For the Fighters<sup>TM</sup></span></h4>
                <h6>INBRIJA may start to work in as little as 10 minutes.</h6>
                <Link to="/about-inbrija#talking-with-doctor" id="HP_talk_doc">Talk with your doctor <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 8L17 8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M11 1.58301L17.4167 7.99967L11 14.4163" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </Link>
              </div>
              <div className='hero-section-image'>
                <img src="/assets/images/INBRIJA_SWIMMER_A_FINAL 1.webp" loading="lazy" className='img-fluid' alt={`Side profile of a woman wearing a swim cap. Her eyes are closed. The words on the side of her swim cap are "So sluggish but I'll fight it. I won't let it stop me.`} />
                <div className="actor_portrayal">Actor portrayal.</div>
              </div>
            </div>
          </div>
        </HeaderMenu>
        <div className="brush-style show_desktop">
        </div>
        <div className="show_mobile brush-style-mob" style={{ backgroundImage: `url('/assets/images/submenu.webp')` }}>
          <a className="talk-button-mobile" href="/about-inbrija#talking-with-doctor" id="HP_talk_doc"> Talk to your Doctor
            <svg style={{ marginLeft: '10px' }} width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8.00049L17 8.00049" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11 1.5835L17.4167 8.00016L11 14.4168" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </a>
        </div>

        <div className="rachel-section">
          <div className="container">
            <div className="rachel-intro-section">
              <h2>10 minutes could make the difference between losing your moments to symptom return and fighting to get them back</h2>
              <h5 className="show_desktop">INBRIJA is the on-demand levodopa rescue inhaler that can help you get back to your day. Do not orally inhale more than 1 dose (2 capsules) for any OFF period. Do not take more than 5 doses in a day.</h5>
              <h5 className="show_mobile">INBRIJA is the on-demand levodopa rescue inhaler that can help you get back to your day. Do not orally inhale more than 1 dose (2 capsules) for any OFF period. Do not take more than 5 doses in a day.</h5>
            </div>
          </div>
        </div>

        <div className="safety-info-homepage container">
          <h6 className="">Selected Important Safety Information</h6>
          <h5 className="">Before using INBRIJA, tell your healthcare provider about your medical conditions, including:</h5>
          <ul>
            <li>asthma, chronic obstructive pulmonary disease (COPD), or any chronic lung disease</li>
            <li>daytime sleepiness, sleep disorders, sleepiness/drowsiness without warning, or use of medicine that increases sleepiness, including antidepressants or antipsychotics</li>
            <li>dizziness, nausea, sweating, or fainting when standing up</li>
            <li>abnormal movement (dyskinesia)</li>
            <li>mental health problems such as hallucinations or psychosis</li>
            <li>uncontrollable urges like gambling, sexual urges, spending money, or binge eating</li>
            <li>glaucoma</li>
            <li>pregnancy or plans to become pregnant. It is unknown if INBRIJA will harm an unborn baby.</li>
            <li>breastfeeding or plans to breastfeed. Levodopa can pass into breastmilk and it is unknown if it can harm the baby.</li>
          </ul>
          <h6 onClick={executeScroll} className="text-underline">Please see below for additional Important Safety Information.</h6 >
        </div>
        <div className="symptom">
          <div className="container-1500">
            <div className='symptom-home'>
              <div className='symptom-home-img show_desktop'>
                <LazyLoadImage src="/assets/images/INBRIJA_HERO.webp" className='img-fluid ' alt={`A woman with a towel that says "I'll be ready today. I've got this."`} loading='lazy' />
                <div className="actor_portrayal">Actor portrayal.</div>
              </div>
              <div className='content'>
                <div className="highlited-text">Are your Parkinson's Symptoms Returning?</div>
                <h3>You may find yourself avoiding going out or making plans because you’re concerned about symptom return.</h3>
                <p className="">Symptom return can take you out of the moment, disrupting your day, and may even cause you to leave activities and events early when you feel symptoms emerging. </p>
                <p className="">This can look like missing work or not going out to eat, seeing friends and family, and/or participating in your favorite activities.</p>
                <a href="what-are-off-periods" id="HP_what_off_per"><button>What Are OFF Periods?</button></a>
              </div>
            </div>
          </div>
        </div>
        <div className="navigation-bar">
          <div className="row">
            <div className="col-md-12">
              <a id="HP_doctor_dis_prep" href="/about-inbrija#talking-with-doctor"> Prepare for Discussions With Your Doctor </a>
            </div>
          </div>
        </div>
        <div ref={myRef}>
          <Isi />
        </div>
        {show ?
          <IsiFrame onClick={executeScroll} /> : ''}
        <Footer code="06/23 INB13297" year="2023"
          title="INBRIJA® (levodopa inhalation powder) for Patients"
          des="Learn about INBRIJA, Prescription Support Services, and downloadable resources. Watch a video about a person who uses INBRIJA. See Indication, Important Safety Information, and Patient Information Leaflet."
        />
      </div>
    </MainLayout>
  )
}