import React from 'react';
import './Isi.css'

export default function () {
  const getChromeVersion = () => {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (raw) {
      return parseInt(raw[2], 10);
    }
    else {
      return 100;
    }
  }

  const handlePdf = () => {
    var ddg_file = '/pdfs/patient-information.pdf';
    if (window.location.pathname.includes('informacion-en-espanol')) {
      ddg_file = '/pdfs/guia-de-conversacion-con-medicos.pdf';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + '//' + window.location.hostname + '/pdf-resources');

    myUrlWithParams.searchParams.append('file', ddg_file);
    if (urlParams.get('utm_campaign')) {
      myUrlWithParams.searchParams.append('utm_campaign', urlParams.get('utm_campaign'));
    }
    if (urlParams.get('utm_medium')) {
      myUrlWithParams.searchParams.append('utm_medium', urlParams.get('utm_medium'));
    }
    if (urlParams.get('utm_source')) {
      myUrlWithParams.searchParams.append('utm_source', urlParams.get('utm_source'));
    }
    if (urlParams.get('utm_content')) {
      myUrlWithParams.searchParams.append('utm_content', urlParams.get('utm_content'));
    }
    window.open(myUrlWithParams);
  }

  return (
    <div className='isi-container-main container'>
      <h5>Indication</h5>
      <p>INBRIJA is a prescription medicine used when needed for OFF episodes in adults with Parkinson's treated with regular carbidopa/levodopa medicine. INBRIJA does not replace regular carbidopa/levodopa medicine.</p>
      <h5 className='mb12'>Important Safety Information</h5>
      <p>Do not use INBRIJA if you take or have taken a nonselective monoamine oxidase inhibitor such as phenelzine or tranylcypromine within the last 2 weeks.</p>
      <p>Before using INBRIJA, tell your healthcare provider about your medical conditions, including:</p>
      <ul>
        <li>asthma, chronic obstructive pulmonary disease (COPD), or any chronic lung disease</li>
        <li>daytime sleepiness, sleep disorders, sleepiness/drowsiness without warning, or use of medicine that increases sleepiness, including antidepressants or antipsychotics</li>
        <li>dizziness, nausea, sweating, or fainting when standing up</li>
        <li>abnormal movement (dyskinesia)</li>
        <li>mental health problems such as hallucinations or psychosis</li>
        <li>uncontrollable urges like gambling, sexual urges, spending money, or binge eating</li>
        <li>glaucoma</li>
        <li>pregnancy or plans to become pregnant. It is unknown if INBRIJA will harm an unborn baby.</li>
        <li>breastfeeding or plans to breastfeed. Levodopa can pass into breastmilk and it is unknown if it can harm the baby.</li>
      </ul>
      <p>Tell your healthcare provider if you take:</p>
      <ul>
        <li>MAO-B inhibitors</li>
        <li>dopamine (D2) antagonists (including phenothiazines, butyrophenones, risperidone, metoclopramide)</li>
        <li>isoniazid</li>
        <li>iron salts or multivitamins that contain iron salts</li>
      </ul>
      <p className='padding-mobile'><b>Do not</b> drive, operate machinery, or do other activities until you know how INBRIJA affects you. Sleepiness and falling asleep suddenly can happen as late as a year after treatment is started.</p>
      <p>Tell your healthcare provider if you experience the following side effects:</p>
      <ul>
        <li><b>falling asleep during normal daily activities</b> with or without warning. If you become drowsy, do not drive or do activities where you need to be alert. Chances of falling asleep during normal activities increases if you take medicine that cause sleepiness. </li>
        <li><b>withdrawal-emergent hyperpyrexia and confusion</b> (fever, stiff muscles, or changes in breathing and heartbeat) if you suddenly stop using INBRIJA or carbidopa/levodopa, or suddenly lower your dose of carbidopa/levodopa.</li>
        <li><b>low blood pressure</b> when standing up (that may be with dizziness, fainting, nausea, and sweating). Get up slowly after sitting/lying down.</li>
        <li><b>hallucinations and other psychosis -</b> INBRIJA may cause or worsen seeing/hearing/believing things that are not real; confusion, disorientation, or disorganized thinking; trouble sleeping; dreaming a lot; being overly suspicious or feeling people want to harm you; acting aggressive; and feeling agitated/restless.</li>
        <li><b> unusual uncontrollable urges </b> such as gambling, binge eating, shopping, and sexual urges has occurred in some people using medicine like INBRIJA.</li>
        <li><b>uncontrolled, sudden body movements (dyskinesia)</b> may be caused or worsened by INBRIJA. INBRIJA may need to be stopped or other Parkinson's medicines may need to be changed.</li>
        <li><b>bronchospasm </b> - people with asthma, COPD, or other lung diseases may wheeze or have difficulty breathing after inhaling INBRIJA. If this occurs, stop taking INBRIJA and seek immediate medical attention.</li>
        <li><b>increased eye pressure </b> in patients with glaucoma. Your healthcare provider should monitor this.</li>
        <li><b>changes in certain lab values</b> including liver tests </li>
      </ul>
      <p className='letter-spacing'>The most common side effects of INBRIJA are cough, upper respiratory tract infection, nausea, and change in the color of saliva or spit.</p>
      <p>Do not orally inhale more than 1 dose (2 capsules) for any OFF period. Do not take more than 5 doses (10 capsules) in a day.</p>
      <p className='link'>Please see the <a href="/pdfs/patient-information.pdf" className='patient-info' target='_blank' id='PIL'>Patient Information Leaflet</a>.</p>
    </div>
  )
}