import React, { useState } from 'react';
import './common-question.css';
import { Link, useNavigate } from 'react-router-dom';
import { img } from 'react-lazy-load-image-component';

export default function CommonQuestion({ isiScroll, peterhowfast }) {

  const [showOffPeriod1, setShowOffperiod1] = useState(false);
  const [showOffPeriod2, setShowOffperiod2] = useState(false);
  const [showAllOffPeriod, setShowAllOffPeriod] = useState(false);
  const [showAboutInb1, setShowAboutInb1] = useState(false);
  const [showAboutInb2, setShowAboutInb2] = useState(false);
  const [showAboutInb3, setShowAboutInb3] = useState(false);
  const [showAboutInb4, setShowAboutInb4] = useState(false);
  const [showAboutInb5, setShowAboutInb5] = useState(false);
  const [showAboutInb6, setShowAboutInb6] = useState(false);
  const [showAboutInb7, setShowAboutInb7] = useState(false);
  const [showTalkDr, setShowTalkDr] = useState(false);
  const [showAllTalkDr, setShowAllTalkDr] = useState(false);
  const [showAllAboutInb, setShowAllAboutInb] = useState(false);
  const [showUseInb1, setShowUseInb1] = useState(false);
  const [showUseInb2, setShowUseInb2] = useState(false);
  const [showUseInb3, setShowUseInb3] = useState(false);
  const [showUseInb4, setShowUseInb4] = useState(false);
  const [showUseInb5, setShowUseInb5] = useState(false);
  const [showUseInb6, setShowUseInb6] = useState(false);
  const [showUseInb7, setShowUseInb7] = useState(false);
  const [showUseInb8, setShowUseInb8] = useState(false);
  const [showUseInb9, setShowUseInb9] = useState(false);
  const [showUseInb10, setShowUseInb10] = useState(false);
  const [showUseInb11, setShowUseInb11] = useState(false);
  const [showUseInb12, setShowUseInb12] = useState(false);
  const [showUseInb13, setShowUseInb13] = useState(false);
  const [showAllUseInb, setShowAllUseInb] = useState(false);
  const [showSupport1, setShowSupport1] = useState(false);
  const [showSupport2, setShowSupport2] = useState(false);
  const [showSupport3, setShowSupport3] = useState(false);
  const [showAllSupport, setShowAllSupport] = useState(false);

  const getChromeVersion = () => {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (raw) {
      return parseInt(raw[2], 10);
    }
    else {
      return 100;
    }
  }

  const ShowAllOffPeriod = () => {
    setShowOffperiod1(true)
    setShowOffperiod2(true)
  }
  const HideAllOffPeriod = () => {
    setShowOffperiod1(false)
    setShowOffperiod2(false)
  }

  const ShowAllAboutInbrija = () => {
    setShowAboutInb1(true);
    setShowAboutInb2(true);
    setShowAboutInb3(true);
    setShowAboutInb4(true);
    setShowAboutInb5(true);
    setShowAboutInb6(true);
    setShowAboutInb7(true);
  }

  const HideAllAboutInbrija = () => {
    setShowAboutInb1(false);
    setShowAboutInb2(false);
    setShowAboutInb3(false);
    setShowAboutInb4(false);
    setShowAboutInb5(false);
    setShowAboutInb6(false);
    setShowAboutInb7(false);
  }

  const ShowAllUseInb = () => {
    setShowUseInb1(true)
    setShowUseInb2(true)
    setShowUseInb3(true)
    setShowUseInb4(true)
    setShowUseInb5(true)
    setShowUseInb6(true)
    setShowUseInb7(true)
    setShowUseInb8(true)
    setShowUseInb9(true)
    setShowUseInb10(true)
    setShowUseInb11(true)
    setShowUseInb12(true)
    setShowUseInb13(true)
  }

  const HideAllUseInb = () => {
    setShowUseInb1(false)
    setShowUseInb2(false)
    setShowUseInb3(false)
    setShowUseInb4(false)
    setShowUseInb5(false)
    setShowUseInb6(false)
    setShowUseInb7(false)
    setShowUseInb8(false)
    setShowUseInb9(false)
    setShowUseInb10(false)
    setShowUseInb11(false)
    setShowUseInb12(false)
    setShowUseInb13(false)
  }

  const ShowAllSupport = () => {
    setShowSupport1(true)
    setShowSupport2(true)
    setShowSupport3(true)
  }

  const HideAllSupport = () => {
    setShowSupport1(false)
    setShowSupport2(false)
    setShowSupport3(false)
  }

  const handlePatientIfuPdf = () => {
    var ddg_file = '/pdfs/inbrija-patient-info-IFU.pdf';
    if (window.location.pathname.includes('informacion-en-espanol')) {
      ddg_file = '/pdfs/guia-de-conversacion-con-medicos.pdf';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + '//' + window.location.hostname + '/pdf-resources');

    myUrlWithParams.searchParams.append('file', ddg_file);
    if (urlParams.get('utm_campaign')) {
      myUrlWithParams.searchParams.append('utm_campaign', urlParams.get('utm_campaign'));
    }
    if (urlParams.get('utm_medium')) {
      myUrlWithParams.searchParams.append('utm_medium', urlParams.get('utm_medium'));
    }
    if (urlParams.get('utm_source')) {
      myUrlWithParams.searchParams.append('utm_source', urlParams.get('utm_source'));
    }
    if (urlParams.get('utm_content')) {
      myUrlWithParams.searchParams.append('utm_content', urlParams.get('utm_content'));
    }
    window.open(myUrlWithParams);
  }

  const handleInstPdf = () => {
    var ddg_file = '/pdfs/inbrija-instructions-for-use.pdf';
    if (window.location.pathname.includes('informacion-en-espanol')) {
      ddg_file = '/pdfs/guia-de-conversacion-con-medicos.pdf';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + '//' + window.location.hostname + '/pdf-resources');

    myUrlWithParams.searchParams.append('file', ddg_file);
    if (urlParams.get('utm_campaign')) {
      myUrlWithParams.searchParams.append('utm_campaign', urlParams.get('utm_campaign'));
    }
    if (urlParams.get('utm_medium')) {
      myUrlWithParams.searchParams.append('utm_medium', urlParams.get('utm_medium'));
    }
    if (urlParams.get('utm_source')) {
      myUrlWithParams.searchParams.append('utm_source', urlParams.get('utm_source'));
    }
    if (urlParams.get('utm_content')) {
      myUrlWithParams.searchParams.append('utm_content', urlParams.get('utm_content'));
    }
    window.open(myUrlWithParams);
  }

  const handleHelpfulPdf = () => {
    var ddg_file = '/pdfs/helpful-hints.pdf';
    if (window.location.pathname.includes('informacion-en-espanol')) {
      ddg_file = '/pdfs/guia-de-conversacion-con-medicos.pdf';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + '//' + window.location.hostname + '/pdf-resources');

    myUrlWithParams.searchParams.append('file', ddg_file);
    if (urlParams.get('utm_campaign')) {
      myUrlWithParams.searchParams.append('utm_campaign', urlParams.get('utm_campaign'));
    }
    if (urlParams.get('utm_medium')) {
      myUrlWithParams.searchParams.append('utm_medium', urlParams.get('utm_medium'));
    }
    if (urlParams.get('utm_source')) {
      myUrlWithParams.searchParams.append('utm_source', urlParams.get('utm_source'));
    }
    if (urlParams.get('utm_content')) {
      myUrlWithParams.searchParams.append('utm_content', urlParams.get('utm_content'));
    }
    window.open(myUrlWithParams);
  }

  const handleVideoClick = (eventType, clickClass) => {
    window.gtag('event', eventType, {
      // 'event_category': 'video_play',
      'event_category': 'video_start',
      'event_label': clickClass,
      'page_location': window.location.href
    });
  }

  const DisbableClick = () => {
    if (getChromeVersion() < 60) {
      return "disable-link-click"
    }
    else {
      return ""
    }
  }

  return (
    <div className='faq-container'>
      <div className='faq-group'>
        <div className='faq-heading'>
          <h1>
            OFF periods
          </h1>
          {showOffPeriod1 && showOffPeriod2 ? <div onClick={() => HideAllOffPeriod()}>Hide Answers</div> : <div onClick={() => ShowAllOffPeriod()}>Show Answers</div>
          }
        </div>

        <div className={showOffPeriod1 ? "faq-open pointer" : 'pointer'} onClick={showOffPeriod1 ? () => setShowOffperiod1(false) : () => setShowOffperiod1(true)}>
          <div className={showOffPeriod1 ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showOffPeriod1 ? () => setShowOffperiod1(false) : () => setShowOffperiod1(true)}>
              What is an OFF period or OFF episode?
            </h6>
            {showOffPeriod1 ? <div className="pointer" onClick={() => setShowOffperiod1(false)}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div> :
              <div className="pointer" onClick={() => setShowOffperiod1(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

              </div>}

          </div>
          {showOffPeriod1 ? (
            <div>
              <p className="answer-font">
                Many people with Parkinson's experience the return of symptoms between regular doses of medication, which is called an OFF period or OFF episode.
              </p>
              <p className="answer-font">
                These can include the return of motor symptoms (related to movement) or nonmotor symptoms (unrelated to movement). Motor symptoms may include tremor (shaking), problems with balance, or stiffness. Nonmotor symptoms may include anxiety, mood changes, or difficulty thinking. Symptoms vary from person to person. In a large clinical trial, INBRIJA began to improve motor symptoms in as soon as 10 minutes (the first time point measured) and significantly improved them by 30 minutes. INBRIJA was not studied for nonmotor symptoms.
              </p>
              <p className="answer-font">
                Please speak with your healthcare provider if you have further questions about the return of symptoms or to see if INBRIJA may be right for you. Consider also using the <a href="/about-inbrija#talking-with-doctor" id='common_DDG'>Doctor Discussion Guide</a>.
              </p>
            </div>
          ) : null}
        </div>
        <div className={showOffPeriod2 ? "faq-open pointer" : 'pointer'} onClick={showOffPeriod2 ? () => setShowOffperiod2(false) : () => setShowOffperiod2(true)}>
          <div className={showOffPeriod2 ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showOffPeriod2 ? () => setShowOffperiod2(false) : () => setShowOffperiod2(true)}>
              Does everyone with Parkinson's experience the return of symptoms between regular doses of medication?
            </h6>
            {showOffPeriod2 ? <div className="pointer" onClick={() => setShowOffperiod2(false)}>
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div> :
              <div className="pointer" onClick={() => setShowOffperiod2(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showOffPeriod2 ? (
            <div>
              <p className="answer-font">
                About 40% of the approximately 1 million people in the US living with Parkinson's will experience the return of symptoms between regular doses of medication.
              </p>
              <p className="answer-font">
                In an online survey of more than 3,000 people with Parkinson's conducted by the Michael J. Fox Foundation, nearly 70% of participants said they experienced at least 2 OFF periods a day.
              </p>
            </div>
          ) : null}
        </div>
      </div>

      <div className='faq-group'>
        <div className='faq-heading'>
          <h1 className='pointer'>
            About INBRIJA
          </h1>
          {
            showAboutInb1 && showAboutInb2 && showAboutInb3 && showAboutInb4 && showAboutInb5 && showAboutInb6 && showAboutInb7
              ? <div onClick={() => HideAllAboutInbrija()}>Hide Answers</div> :
              <div onClick={() => ShowAllAboutInbrija()}>Show Answers</div>}
        </div>

        <div className={showAboutInb1 ? "faq-open pointer" : 'pointer'} onClick={showAboutInb1 ? () => setShowAboutInb1(false) : () => setShowAboutInb1(true)}>
          <div className={showAboutInb1 ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showAboutInb1 ? () => setShowAboutInb1(false) : () => setShowAboutInb1(true)}>
              What are the most common side effects of INBRIJA?
            </h6>
            {showAboutInb1 ?
              <div className="pointer" onClick={() => setShowAboutInb1(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> : <div className="pointer" onClick={() => setShowAboutInb1(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAboutInb1 ? (
            <div>
              <p className="answer-font">
                The most common side effects of INBRIJA are cough, upper respiratory tract infection, nausea, and change in the color of saliva or spit.
              </p>
            </div>
          ) : null}
        </div>

        <div className={showAboutInb2 || showAllAboutInb ? "faq-open pointer" : 'pointer'} >
          <div className={showAboutInb2 || showAllAboutInb ? "faq-accordion-open" : 'faq-accordion'} onClick={showAboutInb2 || showAllAboutInb ? () => setShowAboutInb2(false) : () => setShowAboutInb2(true)}>
            <h6 onClick={showAboutInb2 || showAllAboutInb ? () => setShowAboutInb2(false) : () => setShowAboutInb2(true)}>
              How long does it take for INBRIJA to start to work?
            </h6>
            {showAboutInb2 || showAllAboutInb ?
              <div className="pointer" onClick={() => setShowAboutInb2(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowAboutInb2(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllAboutInb || showAboutInb2 ? (
            <div>

              <div className='answer-image '>
                <a href='/common-questions#peter-how-fast' onClick={() => handleVideoClick('just_ask_peter_videos', 'common_watch_peter_fast_2')}> <img src="/assets/images/peter-howfast-thum-1.webp" className='pointer align-baseline peter-thumbnail-img peter-how-fast' alt='JustAskPeter video thumbnail' /></a>
                <div className='answer-image-content'>
                  <p className="answer-font">In a large clinical trial, INBRIJA started to work as early as 10 minutes, the first time point measured, and meaningful improvement in motor symptoms was seen by 30 minutes. However, individual results may vary.</p>
                  <p className="answer-font">Please speak with your healthcare provider about your question and how it pertains to your care or to see if INBRIJA may be right for you.</p>
                </div>
              </div>

            </div>

          ) : null}

        </div>
        <div className={showAboutInb3 || showAllAboutInb ? "faq-open pointer" : 'pointer'} onClick={showAboutInb3 || showAllAboutInb ? () => setShowAboutInb3(false) : () => setShowAboutInb3(true)}>
          <div className={showAboutInb3 || showAllAboutInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showAboutInb3 || showAllAboutInb ? () => setShowAboutInb3(false) : () => setShowAboutInb3(true)} >
              How long will INBRIJA last? How long will it work?
            </h6>
            {showAboutInb3 || showAllAboutInb ?
              <div className="pointer" onClick={() => setShowAboutInb3(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowAboutInb3(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllAboutInb || showAboutInb3 ? (
            <div>
              <p className="answer-font">How long the effects of INBRIJA last was not specifically measured in the large INBRIJA clinical trial. In the large clinical trial of INBRIJA, improvement in motor function and achieving and maintaining ON were assessed in patients out to 60 minutes after the dose. No measurements were taken after this time point.</p>
              <p className="answer-font">Please speak with your healthcare provider about your question and how it pertains to your care or to see if INBRIJA may be right for you.</p>
            </div>
          ) : null}
        </div>
        <div className={showAboutInb4 || showAllAboutInb ? "faq-open pointer" : 'pointer'} onClick={showAboutInb4 || showAllAboutInb ? () => setShowAboutInb4(false) : () => setShowAboutInb4(true)}>
          <div className={showAboutInb4 || showAllAboutInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showAboutInb4 || showAllAboutInb ? () => setShowAboutInb4(false) : () => setShowAboutInb4(true)} >
              Why doesn't INBRIJA replace my other PD medications?
            </h6>
            {showAboutInb4 || showAllAboutInb ?
              <div className="pointer" onClick={() => setShowAboutInb4(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              :
              <div className="pointer" onClick={() => setShowAboutInb4(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllAboutInb || showAboutInb4 ? (
            <div>
              <p className="answer-font">
                INBRIJA is orally inhaled levodopa used as needed to treat the return of your Parkinson's symptoms and is used as an add‑on therapy only, which means it does not replace your usual carbidopa/levodopa regimen. INBRIJA has not been studied in patients not taking their regular carbidopa/levodopa medicine and will not work without it.
              </p>
            </div>
          ) : null}

        </div>
        <div className={showAboutInb5 || showAllAboutInb ? "faq-open pointer" : 'pointer'}>
          <div className={showAboutInb5 || showAllAboutInb ? "faq-accordion-open" : 'faq-accordion'} onClick={showAboutInb5 || showAllAboutInb ? () => setShowAboutInb5(false) : () => setShowAboutInb5(true)}>
            <h6 className='pointer' onClick={showAboutInb5 || showAllAboutInb ? () => setShowAboutInb5(false) : () => setShowAboutInb5(true)} >
              Does the time I take my oral CD/LD need to be modified depending on when INBRIJA is taken?
            </h6>
            {showAboutInb5 || showAllAboutInb ?
              <div className="pointer" onClick={() => setShowAboutInb5(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              :
              <div className="pointer" onClick={() => setShowAboutInb5(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllAboutInb || showAboutInb5 ? (
            <div>
              <p className="answer-font">Use INBRIJA exactly as prescribed.</p>
              <p className="answer-font">
                INBRIJA should be used as soon as someone feels their Parkinson's symptoms start to return. This is important to remember. You should not wait until you feel your symptoms have completely returned.</p>
              <p className="answer-font">Use INBRIJA throughout the day as needed to treat your symptoms as they start to return. Do not use more than 1 dose (2 capsules) per OFF period and do not take more than 5 doses of INBRIJA in 1 day. Otherwise, there is no minimum time required to wait between doses of INBRIJA and oral carbidopa/levodopa or vice versa. Acorda ran a trial where patients were allowed to take both medicines together, inhaling INBRIJA right after taking their oral carbidopa/levodopa dose.</p>
              <p className="answer-font">
                In a large clinical trial, patients kept taking their usual Parkinson's medicines as prescribed by their doctors, and that schedule was not to be changed throughout the trial. They used INBRIJA as needed when Parkinson's symptoms started to return, no more than once per OFF period and up to 5 times a day, while continuing their regular doses of carbidopa/levodopa.
              </p>
              <p className="answer-font">
                Do not change your regular schedule of Parkinson's medication unless told to do so by your healthcare provider. Do not stop taking your regular carbidopa/levodopa as INBRIJA does not replace this daily medicine.
              </p>
              <p className="answer-font">
                Please speak with your healthcare provider about your treatment and if you have further questions.
              </p>
            </div>
          ) : null}
        </div>
        <div className={showAboutInb6 || showAllAboutInb ? "faq-open pointer" : 'pointer'} onClick={showAboutInb6 || showAllAboutInb ? () => setShowAboutInb6(false) : () => setShowAboutInb6(true)}>
          <div className={showAboutInb6 || showAllAboutInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showAboutInb6 || showAllAboutInb ? () => setShowAboutInb6(false) : () => setShowAboutInb6(true)}>
              Can I use INBRIJA when I have Parkinson's symptoms return as soon as I wake up in the morning?
            </h6>
            {showAboutInb6 || showAllAboutInb ?
              <div className="pointer" onClick={() => setShowAboutInb6(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              :
              <div className="pointer" onClick={() => setShowAboutInb6(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllAboutInb || showAboutInb6 ? (
            <div>
              <p className="answer-font">
                INBRIJA can be used to treat the return of symptoms in the morning.</p>
              <p className="answer-font">
                Remember to continue to take your regular carbidopa/levodopa medicine and to speak with your healthcare provider about when to take all of your medicines. In a trial evaluating the safety of INBRIJA to treat early morning OFF, people with Parkinson's used INBRIJA right after their first oral carbidopa/levodopa dose of the day.
              </p>
            </div>
          ) : null}

        </div>
        <div className={showAboutInb7 || showAllAboutInb ? "faq-open pointer" : 'pointer'} onClick={showAboutInb7 || showAllAboutInb ? () => setShowAboutInb7(false) : () => setShowAboutInb7(true)}>
          <div className={showAboutInb7 || showAllAboutInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showAboutInb7 || showAllAboutInb ? () => setShowAboutInb7(false) : () => setShowAboutInb7(true)}>
              Are there reasons why I can't take INBRIJA?
            </h6>
            {showAboutInb7 || showAllAboutInb ?
              <div className="pointer" onClick={() => setShowAboutInb7(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowAboutInb7(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllAboutInb || showAboutInb7 ? (
            <div>
              <p className="answer-font">
                If you take, or within the last 2 weeks you have taken, a nonselective monoamine oxidase inhibitor such as phenelzine or tranylcypromine, do not use INBRIJA. Your blood pressure may increase.</p>
              <p className="answer-font">Before using INBRIJA, tell your healthcare provider if you have asthma, chronic obstructive pulmonary disease (COPD), or any chronic lung disease.
              </p>
              <p className="answer-font">Tell your healthcare provider if you experience bronchospasm. People with asthma, COPD, or other lung diseases may wheeze or have difficulty breathing after inhaling INBRIJA. If this occurs, stop taking INBRIJA and seek immediate medical attention.</p>
              <p className="answer-font">Before using INBRIJA, tell your healthcare provider about any other medical conditions, including:</p>
              <ul>
                <li>daytime sleepiness, sleep disorders, sleepiness/drowsiness without warning, or use of medicine that increases sleepiness, including antidepressants or antipsychotics</li>
                <li>dizziness, nausea, sweating, or fainting when standing up</li>
                <li>abnormal movement (dyskinesia)</li>
                <li>mental health problems such as hallucinations or psychosis</li>
                <li>uncontrollable urges like gambling, sexual urges, spending money, or binge eating</li>
                <li>glaucoma</li>
                <li>pregnancy or plans to become pregnant. It is unknown if INBRIJA will harm an unborn baby.</li>
                <li>breastfeeding or plans to breastfeed. Levodopa can pass into breastmilk and it is unknown if it can harm the baby.</li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
      <div className="faq-group">
        <div className='faq-heading'>
          <h1>
            Talking with your doctor
          </h1>
          {showTalkDr ? <div onClick={() => setShowTalkDr(false)}>Hide Answers</div> :
            <div onClick={() => setShowTalkDr(true)}>Show Answers</div>}
        </div>
        <div className={showTalkDr || showAllTalkDr ? "faq-open pointer" : 'pointer'} onClick={showTalkDr || showAllTalkDr ? () => setShowAllTalkDr(false) : () => setShowAllTalkDr(true)}>
          <div className={showTalkDr || showAllTalkDr ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showTalkDr || showAllTalkDr ? () => setShowAllTalkDr(false) : () => setShowAllTalkDr(true)}>
              What should I tell my doctor before taking INBRIJA?
            </h6>
            {showTalkDr || showAllTalkDr ?
              <div className="pointer" onClick={() => setShowTalkDr(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowTalkDr(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllTalkDr || showTalkDr ? (
            <div>
              <p className="answer-font">Give your doctor as many details as possible about any symptoms you experience between doses of your regular Parkinson's medication. It may be helpful to keep a journal that notes when you have symptoms, what they are, the time of day, and how long they last. Use our <a href='/about-inbrija#talking-with-doctor' id='common_DDG_2'>Doctor Discussion Guide</a> to prepare for a productive conversation with your doctor.</p>
              <p className="answer-font">Before using INBRIJA, tell your healthcare provider about your medical conditions, including:</p>
              <ul>
                <li>asthma, chronic obstructive pulmonary disease (COPD), or any chronic lung disease</li>
                <li>daytime sleepiness, sleep disorders, sleepiness/drowsiness without warning, or use of medicine that increases sleepiness, including antidepressants or antipsychotics</li>
                <li>dizziness, nausea, sweating, or fainting when standing up</li>
                <li>abnormal movement (dyskinesia)</li>
                <li>mental health problems such as hallucinations or psychosis</li>
                <li>uncontrollable urges like gambling, sexual urges, spending money, or binge eating</li>
                <li>glaucoma</li>
                <li>pregnancy or plans to become pregnant. It is unknown if INBRIJA will harm an unborn baby.</li>
                <li>breastfeeding or plans to breastfeed. Levodopa can pass into breastmilk and it is unknown if it can harm the baby.</li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
      <div className="faq-group">


        <div className='faq-heading'>
          <h1>
            Using INBRIJA
          </h1>
          {showUseInb1 && showUseInb2 && showUseInb3 && showUseInb4 && showUseInb5 && showUseInb6 && showUseInb7 && showUseInb8 && showUseInb9 && showUseInb9 && showUseInb10 && showUseInb11 && showUseInb12 && showUseInb13 ?
            <div onClick={() => HideAllUseInb()}>Hide Answers</div> :
            <div onClick={() => ShowAllUseInb()}>Show Answers</div>}
        </div>

        <div className='faq-extra'>
          <div>For information about how to use INBRIJA, including a demonstration video and helpful hints, please see the <br /> <span > <Link className='faq-a' to="/how-to-use-inbrija" id="common_learn_how_use">How to Use INBRIJA</Link></span> page.</div>
          <div className='mt-1'>
            <a className={`faq-a ${DisbableClick()}`} target='_blank' href="/pdfs/inbrija-instructions-for-use.pdf" id='common_IFU'>Please see the INBRIJA Instructions For Use for more information</a>.
          </div>
        </div>

        <div className={showUseInb1 || showAllUseInb ? "faq-open pointer" : 'pointer'} onClick={showUseInb1 || showAllUseInb ? () => setShowUseInb1(false) : () => setShowUseInb1(true)}>
          <div className={showUseInb1 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showUseInb1 || showAllUseInb ? () => setShowUseInb1(false) : () => setShowUseInb1(true)}>
              Will I be able to use INBRIJA while I'm experiencing the return of symptoms?
            </h6>
            {showUseInb1 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb1(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              :
              <div className="pointer" onClick={() => setShowUseInb1(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb1 ? (
            <div>
              <p className="answer-font">Most people in clinical trials were able to use INBRIJA. In fact, 99.8% (628 out of 629) of people with Parkinson's who participated in two clinical trials, after instruction, were able to use INBRIJA in an OFF period.</p>
              <p className="answer-font">Remember to take an INBRIJA dose as soon as you feel Parkinson's symptoms start to return.</p>
            </div>
          ) : null}
        </div>

        <div className={showUseInb2 || showAllUseInb ? "faq-open pointer" : 'pointer'}>
          <div className={showUseInb2 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'} onClick={showUseInb2 || showAllUseInb ? () => setShowUseInb2(false) : () => setShowUseInb2(true)}>
            <h6 className='pointer' onClick={showUseInb2 || showAllUseInb ? () => setShowUseInb2(false) : () => setShowUseInb2(true)}>
              When should I take INBRIJA?
            </h6>
            {showUseInb2 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb2(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              :
              <div className="pointer" onClick={() => setShowUseInb2(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb2 ? (
            <div>

              <div className='answer-image '>
                <a href='/common-questions#peter-when-use'
                  onClick={() => handleVideoClick('just_ask_peter_videos', 'common_watch_peter_when_2')}
                > <img src="/assets/images/peter-howfast-thum-2.webp" alt='Just Ask Peter thumbnail' className='pointer peter-thumbnail-img peter-dosing' /> </a>
                <div className='answer-image-content'>
                  <p className="answer-font">Use INBRIJA exactly as prescribed. INBRIJA should be taken when Parkinson's symptoms start to return. INBRIJA does not replace your regular carbidopa/levodopa medicine. Continue to take your regular carbidopa/levodopa doses at your regularly scheduled times and do not change that schedule unless told to do so by your doctor.</p>
                  <p className="answer-font">Use INBRIJA as needed (2 capsules per dose, no more than 5 doses a day) when your Parkinson's symptoms start to return. Do not use more than 1 dose (2 capsules) per OFF period.</p>
                </div>
              </div>

            </div>
          ) : null}
        </div>


        <div className={showUseInb3 || showAllUseInb ? "faq-open pointer" : 'pointer'} onClick={showUseInb3 || showAllUseInb ? () => setShowUseInb3(false) : () => setShowUseInb3(true)}>
          <div className={showUseInb3 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showUseInb3 || showAllUseInb ? () => setShowUseInb3(false) : () => setShowUseInb3(true)}>
              How do I use the INBRIJA inhaler?
            </h6>
            {showUseInb3 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb3(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              :
              <div className="pointer" onClick={() => setShowUseInb3(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb3 ? (
            <div>
              <p className="answer-font">Get step by step instructions for using your INBRIJA inhaler <a href='/how-to-use-inbrija#peter-demo' id='CQ_Instructions_for_use' onClick={() => handleVideoClick('just_ask_peter_videos', 'common_watch_peter_demo')}>here</a>. You can also download the
                <br /> <span><a href="/pdfs/inbrija-instructions-for-use.pdf" className={DisbableClick()} target="_blank" id='common_IFU'>Instructions For Use</a>.</span>
              </p>
              <p>If you have problems using INBRIJA, contact a Nurse Educator with Prescription Support Services at <br /><span><a href='tel://1-888-887-3447' id='common_888'>1-888-887-3447</a></span>.</p>
              <p>Download helpful hints for using INBRIJA <span><a href="/pdfs/helpful-hints.pdf" target='_blank' className={`faq-a ${DisbableClick()}`} id='common_helpful_hints'>here</a></span>.</p>
            </div>
          ) : null}
        </div>

        <div className={showUseInb4 || showAllUseInb ? "faq-open pointer" : 'pointer'} onClick={showUseInb4 || showAllUseInb ? () => setShowUseInb4(false) : () => setShowUseInb4(true)}>
          <div className={showUseInb4 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showUseInb4 || showAllUseInb ? () => setShowUseInb4(false) : () => setShowUseInb4(true)}>
              Why is one dose in two capsules?
            </h6>
            {showUseInb4 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb4(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              :
              <div className="pointer" onClick={() => setShowUseInb4(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb4 ? (
            <div>
              <p className="answer-font">A full dose of INBRIJA does not fit inside a single capsule, and so a complete dose is 2 capsules.</p>
            </div>
          ) : null}
        </div>

        <div className={showUseInb5 || showAllUseInb ? "faq-open pointer" : 'pointer'} onClick={showUseInb5 || showAllUseInb ? () => setShowUseInb5(false) : () => setShowUseInb5(true)}>
          <div className={showUseInb5 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showUseInb5 || showAllUseInb ? () => setShowUseInb5(false) : () => setShowUseInb5(true)}>
              Why can't I swallow the capsules instead of using the inhaler?
            </h6>
            {showUseInb5 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb5(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowUseInb5(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb5 ? (
            <div>
              <p className="answer-font">INBRIJA capsules must not be swallowed or opened as the intended effect will not be obtained. INBRIJA capsules are only for oral inhalation use (through the mouth) and are only for use with the INBRIJA inhaler. Don’t use the INBRIJA inhaler to take any other medicine.</p>
            </div>
          ) : null}
        </div>

        <div className={showUseInb6 || showAllUseInb ? "faq-open pointer" : 'pointer'} onClick={showUseInb6 || showAllUseInb ? () => setShowUseInb6(false) : () => setShowUseInb6(true)}>
          <div className={showUseInb6 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showUseInb6 || showAllUseInb ? () => setShowUseInb6(false) : () => setShowUseInb6(true)}>
              How should I inhale INBRIJA?
            </h6>
            {showUseInb6 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb6(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              :
              <div className="pointer" onClick={() => setShowUseInb6(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb6 ? (
            <div>
              <p className="answer-font">Refer to the <a href="/pdfs/inbrija-instructions-for-use.pdf" className={DisbableClick()} target='_blank' id='common_IFU_2'>Instructions For Use</a> for complete instructions on using INBRIJA.</p>
              <p className="answer-font">Using INBRIJA may take some practice at first. Remember to stand or sit up straight, look straight ahead and hold the inhaler level while breathing in. Breathe in slowly and gently, just enough to hear or feel the capsule whirl, and hold your breath for a few seconds. Remember, you don't need to breathe in strongly.</p>
              <p className="answer-font">If when inhaling INBRIJA, you heard or felt the capsule “whirl” (spin), then you know the inhaler is working and you got your medicine.</p>
              <p className="answer-font">You may take more than 1 breath per capsule if it's easier for you.</p>
              <p className="answer-font">Get step by step instructions for using your INBRIJA inhaler <a href="/common-questions#peter-videos" id='common_watch_peter_demo_2'>here</a>. You can watch a <a href='/how-to-use-inbrija#peter-demo' onClick={() => handleVideoClick('demo_video', 'common_watch_peter_demo_3')}>Demonstration Video</a> or download the <a href="/pdfs/inbrija-instructions-for-use.pdf" className={DisbableClick()} target='_blank' id='common_IFU_3'>Instructions For Use</a>.</p>
              <p>If you have problems using INBRIJA, contact a Nurse Educator with Prescription Support Services at <a href="tel://1-888-887-3447" id='common_888_2'>1-888-887-3447</a>.</p>
              <p className="answer-font">Download helpful hints for using INBRIJA <a href="/pdfs/helpful-hints.pdf" className={DisbableClick()} target='_blank' id='common_helpful_hints_2'>here</a>.</p>
            </div>
          ) : null}
        </div>

        <div className={showUseInb7 || showAllUseInb ? "faq-open pointer" : 'pointer'} >
          <div className={showUseInb7 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'} onClick={showUseInb7 || showAllUseInb ? () => setShowUseInb7(false) : () => setShowUseInb7(true)}>
            <h6 className='pointer' onClick={showUseInb7 || showAllUseInb ? () => setShowUseInb7(false) : () => setShowUseInb7(true)}>
              What if I don't hear or feel the whirl when I am using the inhaler?
            </h6>
            {showUseInb7 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb7(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowUseInb7(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb7 ? (
            <div className='answer-image '>
              <a href='/common-questions#peter-whirl' onClick={() => handleVideoClick('just_ask_peter_videos', 'common_watch_peter_whirl_2')}>
                <img src="/assets/images/peter-howfast-thum-3.webp" alt="Just Ask Peter thumbnail" className='pointer peter-thumbnail-img peter-whirl' />
              </a>
              <div className='answer-image-content'>
                <p className="answer-font">If you don't hear or feel the whirl (capsule spinning), inhale again using the same capsule taking slightly stronger breaths until you do. It is okay to take more than 1 breath per capsule. If you don't hear/feel the whirl, you should see if cleaning it helps by wiping the inside and outside of the mouthpiece with a dry tissue or dry cotton swab to remove any powder. Do not rinse the mouthpiece or get the inhaler wet.</p>
                <p className="answer-font">If you have any questions, ask your healthcare provider or pharmacist.</p>
                <p className="answer-font">If you have problems using INBRIJA, contact a Nurse Educator with Prescription</p>
                <p className="answer-font">Support Services at <span ><a className='faq-a' href="tel://1-888-887-3447" id='common_888_3'>1-888-887-3447</a>.</span></p>
                <p className="answer-font">Download helpful hints for using INBRIJA <span><a target='_blank' href="/pdfs/helpful-hints.pdf" className={`faq-a ${DisbableClick()}`} id='common_helpful_hints_3'>here</a></span>.</p>
              </div>
            </div>
          ) : null}
        </div>
        <div className={showUseInb8 || showAllUseInb ? "faq-open pointer" : 'pointer'}>
          <div className={showUseInb8 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'} onClick={showUseInb8 || showAllUseInb ? () => setShowUseInb8(false) : () => setShowUseInb8(true)}>
            <h6 className='pointer' onClick={showUseInb8 || showAllUseInb ? () => setShowUseInb8(false) : () => setShowUseInb8(true)}>
              What can I do to help with cough when taking INBRIJA?
            </h6>
            {showUseInb8 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb8(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowUseInb8(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb8 ? (
            <div className='answer-image '>
              <a href='/common-questions#peter-cough' onClick={() => handleVideoClick('just_ask_peter_videos', 'common_watch_peter_cough_2')}>
                <img src="/assets/images/peter-howfast-thum-1.webp" alt="Just Ask Peter thumbnail" className='pointer peter-thumbnail-img peter-cough' />
              </a>
              <div className='answer-image-content'>
                <p className="answer-font">When using INBRIJA, it is quite common to cough as you breathe in. Try not to cough out the medicine as this will lessen how much you get into your body.</p>
                <p className="answer-font">Using INBRIJA may take some practice. Here are a few tips that may help with using INBRIJA:</p>
                <ul>
                  <li><span className='bold'>Stand or sit up straight</span> and look straight ahead while breathing in. Hold the inhaler level when you're using INBRIJA.</li>
                  <li>Some people have found that sipping liquid before and after breathing in the medicine helps.</li>
                  <li><span className='bold'>Breathe in slowly and gently</span>, just enough to hear or feel the capsule whirl, and hold your breath for a few seconds. If you don't hear or feel the whirl then take stronger breaths until you do. Hearing/feeling the whirl means your breath is enough to get the medicine into your lungs. Just remember, you don't need to breathe in strongly.</li>
                  <li><span className='bold'>You may take more than 1 breath</span> per capsule if it is easier for you.</li>
                  <li>As you're breathing in, if you feel you might cough, hold your breath for a few seconds and wait until the feeling goes away. If you want, you can then take a sip of liquid. Then breathe in again with the same capsule. For some people, coughing might feel like the sensation of choking. And don't forget, if you cough, it's expected. Just breathe in again with the same capsule.</li>
                </ul>
                <p className="answer-font">Before using INBRIJA, tell your healthcare provider if you have asthma, chronic obstructive pulmonary disease (COPD), or any chronic lung disease. INBRIJA is not recommended if you do. People with such lung diseases may wheeze or have difficulty breathing after inhaling INBRIJA. If this occurs, stop taking INBRIJA and seek immediate medical attention.</p>
                <p className="answer-font">For additional information on cough or management of cough, talk with your healthcare provider or contact a Nurse Educator at <span ><a className='faq-a' href="tel://1-888-887-3447" id='common_888_4'> 1-888-887-3447</a></span>. You can also download helpful hints for using INBRIJA <span ><a className={`faq-a ${DisbableClick()}`} target='_blank' href="/pdfs/helpful-hints.pdf" id='common_helpful_hints_4'>here</a></span>.</p>
              </div>
            </div>
          ) : null}
        </div>

        <div className={showUseInb9 || showAllUseInb ? "faq-open pointer" : 'pointer'} >
          <div className={showUseInb9 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'} onClick={showUseInb9 || showAllUseInb ? () => setShowUseInb9(false) : () => setShowUseInb9(true)}>
            <h6 className='pointer' onClick={showUseInb9 || showAllUseInb ? () => setShowUseInb9(false) : () => setShowUseInb9(true)}>
              What can I do to prevent my INBRIJA inhaler from clogging?
            </h6>
            {showUseInb9 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb9(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowUseInb9(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb9 ? (
            <div className='answer-image '>
              <a href='/common-questions#peter-cleaning' onClick={() => handleVideoClick('just_ask_peter_videos', 'common_watch_peter_clean_2')}>
                <img src="/assets/images/peter-howfast-thum-5.webp" alt="Just Ask Peter thumbnail" className='pointer peter-thumbnail-img peter-cleaning' />
              </a>
              <div className='answer-image-content'>
                <p className="answer-font">It is normal for some powder to remain in or on the inhaler and in the capsules after use. When the powder is exposed to moisture, such as from your breath, saliva, spit, or air it can clump together, which may contribute to the inhaler clogging. So remember to keep the inhaler and capsules dry. <span className='bold'>Do not wash the inhaler</span> and do not breathe or cough into the mouthpiece of the inhaler. Avoid any spit or saliva from entering the inhaler during inhalation.</p>
                <p className="answer-font">To try and avoid the buildup of excess powder in the inhaler, you may clean the inhaler by using a new, dry cotton swab to wipe the powder off of the holes from both sides of the mouthpiece with a circular motion. (See Step 14 in <a href="/pdfs/inbrija-patient-info-IFU.pdf" className={DisbableClick()} target='_blank' id='common_IFU_4'><span className='faq-a'>Instructions For Use</span></a>). You can also use a dry tissue to wipe the outside of the mouthpiece, as needed. <span className="bold" >Do not clean any other parts of the inhaler.
                  Do not rinse the mouthpiece or get the inhaler wet.</span> Always keep the inhaler and capsules dry.</p>
                <p className="answer-font">
                  Remember to throw out the inhaler after all capsules in the carton have been used. Use the new inhaler that comes with each INBRIJA carton.</p>
              </div>
            </div>
          ) : null}
        </div>

        <div className={showUseInb10 || showAllUseInb ? "faq-open pointer" : 'pointer'} onClick={showUseInb10 || showAllUseInb ? () => setShowUseInb10(false) : () => setShowUseInb10(true)}>
          <div className={showUseInb10 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showUseInb10 || showAllUseInb ? () => setShowUseInb10(false) : () => setShowUseInb10(true)}>
              What if powder is left in the inhaler or capsule after inhaling a dose?
            </h6>
            {showUseInb10 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb10(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              :
              <div className="pointer" onClick={() => setShowUseInb10(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb10 ? (

            <div>
              <p className="answer-font">It is normal for some powder to remain in or on the inhaler and in the capsules after inhalation. If when inhaling INBRIJA, you heard or felt the capsule "whirl" (spin), then you know the inhaler was working and you got your medicine. You may also take more than 1 breath per capsule if it is easier for you.</p>
              <p className="answer-font">If you see powder on or in the inhaler, or if you don't hear or feel the whirl, you may clean it by wiping the inside and outside of the mouthpiece with a dry tissue or dry cotton swab.<span className='bold'> Do not rinse the mouthpiece or get the inhaler wet.</span></p>
              <p className="answer-font">Remember to throw out the inhaler after all capsules in the carton have been used. Use the new inhaler that comes with each INBRIJA carton.</p>
              <p className="answer-font">Please speak with your healthcare provider about your question and if you have further questions.</p>
            </div>

          ) : null}
        </div>

        <div className={showUseInb11 || showAllUseInb ? "faq-open pointer" : 'pointer'} onClick={showUseInb11 || showAllUseInb ? () => setShowUseInb11(false) : () => setShowUseInb11(true)}>
          <div className={showUseInb11 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showUseInb11 || showAllUseInb ? () => setShowUseInb11(false) : () => setShowUseInb11(true)}>
              What if the powder left in my INBRIJA inhaler is dark or discolored?
            </h6>
            {showUseInb11 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb11(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowUseInb11(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb11 ? (
            <div className='answer-image '>
              <div className='answer-image-content'>
                <p className="answer-font">It is normal for some powder to remain in or on the inhaler and in the capsules after use. When the levodopa powder (the active ingredient in INBRIJA) is exposed to moisture, such as from your breath, saliva, spit, or air, it can darken.</p>
                <p className="answer-font">If you see powder on or in the inhaler, you may clean it by wiping the inside and outside of the mouthpiece with a dry tissue or dry cotton swab.</p>
                <p className="answer-font">If when inhaling INBRIJA, you heard or felt the capsule "whirl" (spin) then you know the inhaler was working and you got your medicine.</p>
                <p className="answer-font">If you do not hear or feel the whirl, you should see if cleaning it will help by wiping the inside and outside of the mouthpiece with a dry tissue or dry cotton swab to remove any powder.</p>
                <p className='bold'>Do not rinse the mouthpiece or get the inhaler wet.</p>
                <p className="answer-font">Remember to throw out the inhaler after all capsules in the carton have been used. Use the new inhaler that comes with each INBRIJA carton.</p>
                <p className="answer-font">Please speak with your healthcare provider about your question and if you have further questions.</p>
              </div>
            </div>
          ) : null}
        </div>

        <div className={showUseInb12 || showAllUseInb ? "faq-open pointer" : 'pointer'} >
          <div className={showUseInb12 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'} onClick={showUseInb12 || showAllUseInb ? () => setShowUseInb12(false) : () => setShowUseInb12(true)}>
            <h6 className='pointer' onClick={showUseInb12 || showAllUseInb ? () => setShowUseInb12(false) : () => setShowUseInb12(true)}>
              Why can't I wash the inhaler?
            </h6>
            {showUseInb12 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb12(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowUseInb12(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllUseInb || showUseInb12 ? (
            <div className='answer-image '>
              <a href='/common-questions#peter-cleaning' onClick={() => handleVideoClick('just_ask_peter_videos', 'common_watch_peter_clean_3')}>
                <img src="/assets/images/peter-howfast-thum-5.webp" className='pointer peter-thumbnail-img peter-cleaning' alt="Just Ask Peter thumbnail" />
              </a>
              <div className='answer-image-content'>
                <p className="answer-font">The INBRIJA inhaler and capsules must be kept dry. It is normal for some powder to remain in or on the inhaler. If you would like to clean the inhaler, see Step 14 in <a href="/pdfs/inbrija-patient-info-IFU.pdf" className={DisbableClick()} target='_blank' id='common_IFU_5'>Instructions For Use</a> for complete cleaning instructions.</p>
              </div>
            </div>
          ) : null}
        </div>

        <div className={showUseInb13 || showAllUseInb ? "faq-open pointer" : 'pointer'} onClick={showUseInb13 || showAllUseInb ? () => setShowUseInb13(false) : () => setShowUseInb13(true)}>
          <div className={showUseInb13 || showAllUseInb ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showUseInb13 || showAllUseInb ? () => setShowUseInb13(false) : () => setShowUseInb13(true)}>
              How long can I keep using the same inhaler?
            </h6>
            {showUseInb13 || showAllUseInb ?
              <div className="pointer" onClick={() => setShowUseInb13(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowUseInb13(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}
          </div>
          {showAllUseInb || showUseInb13 ? (
            <div>
              <p className="answer-font">Use the new inhaler that comes with each carton. Remember to throw out the inhaler after all capsules in a carton have been used.</p>
            </div>
          ) : null}
        </div>
      </div>
      <div className='faq-group'>
        <div className='faq-heading'>
          <h1>
            Getting support
          </h1>
          {showSupport1 && showSupport2 && showSupport3 ? <div onClick={() => HideAllSupport()}>Hide Answers</div> :
            <div onClick={() => ShowAllSupport()}>Show Answers</div>}
        </div>
        <div className={showSupport1 || showAllSupport ? "faq-open pointer" : 'pointer'} onClick={showSupport1 || showAllSupport ? () => setShowSupport1(false) : () => setShowSupport1(true)}>
          <div className={showSupport1 || showAllSupport ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showSupport1 || showAllSupport ? () => setShowSupport1(false) : () => setShowSupport1(true)}>
              What support does Prescription Support Services provide?
            </h6>
            {showSupport1 || showAllSupport ?
              <div className="pointer" onClick={() => setShowSupport1(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              :
              <div className="pointer" onClick={() => setShowSupport1(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}
          </div>
          {showAllSupport || showSupport1 ? (
            <div>
              <p className="answer-font">Prescription Support Services specialists work with your doctor and specialty pharmacy to get you started on INBRIJA. They confirm your coverage and out‑of‑pocket expenses, and help to answer questions you may have about your coverage. In addition, Nurse Educators are available to provide further training tips and answer any questions you may have on how to use INBRIJA. Our Nurse Educators are available to help Monday through Friday, 8 <small>AM</small> to 8 <small>PM</small> Eastern Time. To reach Nurse Educators, call toll-free at <a href="tel:1-888-887-3447" id='common_888_5'>1-888-887-3447</a>.</p>
            </div>
          ) : null}
        </div>
        <div className={showSupport2 || showAllSupport ? "faq-open pointer" : 'pointer'} onClick={showSupport2 || showAllSupport ? () => setShowSupport2(false) : () => setShowSupport2(true)}>
          <div className={showSupport2 || showAllSupport ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showSupport2 || showAllSupport ? () => setShowSupport2(false) : () => setShowSupport2(true)}>
              Whom can I contact if I have additional questions?
            </h6>
            {showSupport2 || showAllSupport ?
              <div className="pointer" onClick={() => setShowSupport2(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowSupport2(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}

          </div>
          {showAllSupport || showSupport2 ? (
            <div>
              <p className="answer-font">If you have additional questions about INBRIJA, feel free to call Prescription Support Services (<a href="tel:1-888-887-3447" id='common_888_6'>1-888-887-3447</a>). Contact your doctor if you have questions about your medical care.</p>
            </div>
          ) : null}
        </div>
        <div className={showSupport3 || showAllSupport ? "faq-open pointer" : 'pointer'} onClick={showSupport3 || showAllSupport ? () => setShowSupport3(false) : () => setShowSupport3(true)}>
          <div className={showSupport3 || showAllSupport ? "faq-accordion-open" : 'faq-accordion'}>
            <h6 className='pointer' onClick={showSupport3 || showAllSupport ? () => setShowSupport3(false) : () => setShowSupport3(true)}>
              What if I can't afford INBRIJA?
            </h6>
            {showSupport3 || showAllSupport ?
              <div className="pointer" onClick={() => setShowSupport3(false)}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 12H19.5" stroke="#7C6991" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> :
              <div className="pointer" onClick={() => setShowSupport3(true)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 5V19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5 12H19" stroke="#4B3F67" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>}
          </div>
          {showAllSupport || showSupport3 ? (
            <div>
              <p className="answer-font">If you have commercial insurance (employer sponsored or individual) and INBRIJA is covered, you may be eligible for co-pay assistance for INBRIJA where allowed by law. Patients who participate in Medicaid, Medicare, or any other US federal healthcare program are not eligible to receive co-pay assistance.</p>
              <p className="answer-font">If you cannot afford INBRIJA, Prescription Support Services may be able to help. You may be eligible for the Patient Assistance Program which is designed to help you get INBRIJA at no cost. Ask us for more information about the program and how to apply.</p>
              <p className="answer-font">If you are enrolled in Medicare and have limited financial resources, you may qualify for the Medicare Extra Help program to help pay for some healthcare and prescription drug costs. Ask us for details about how to apply for the program. The Medicare Extra Help program is a federal program, and is not managed by Acorda.</p>
            </div>
          ) : null}
        </div>
      </div>
    </div >
  )
}